import { colors } from './../../variables';
import styled from "styled-components";

export const HeaderWrapper = styled.header`
  height: 50px;
  /* background-color: ${colors.back}; */
  position: relative;
  padding-left: 40px;
  padding-top: 40px;
  align-items: center;
  display: flex;
  @media (max-width: 991px){
    text-align: center;
    display: block;
    padding-left: 0px;
    padding-top: 15px;
  }
`;

export const Logo = styled.img`
  width: 140px;
  height: auto;
  position: fixed;
  left: 40px;
  top: 40px;
  color: #000;
  text-transform: uppercase;
  font-style: italic;
  margin-right: 20px;
  @media (max-width: 991px){
    width: 110px;
    position: relative;
    top: inherit;
    z-index: 2;
    left: inherit;
    text-align: center;
    margin: 0 auto;
    top: 0px;
  }
`;

export const Catchline = styled.h1`
  position: relative;
  font-size: 19px;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
  font-style: italic;
  color: #000;
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    font-size: 28px;
    margin-top: 30px;
    position: relative;
  }
  @media (max-width: 767px){
    font-size: 20px;
  }
`;