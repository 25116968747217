import * as React from 'react';
import Layout from '../layout';
import { Safari, Text, Title, Message } from './styled';
import { Button } from '../../widgets/globaleStyledWidget';
import { colors, lang } from '../../variables';
import {FormattedMessage} from 'react-intl';

const ErrorPage:React.FunctionComponent = () => {
    return <Layout>
      <Safari>
          <Title><FormattedMessage id="global_txt_denied" /></Title>
      </Safari>
    </Layout>
}

export default ErrorPage;